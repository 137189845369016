import {Html, useProgress} from '@react-three/drei';

export function Loader() {
  const {progress} = useProgress();

  return (
    <Html className="justify-center self-center items-center" center>
      <div className="text-center text-white font-bungee">
        Loading {progress.toFixed(2)}%
      </div>
      <div
        style={{width: progress + '%'}}
        className="h-2 w-40 mt-2 border-white rounded-2xl">
        <div className={`h-2 bg-white rounded-2xl`} />
      </div>
      {progress === 100 ? (
        <div className="text-center mt-2 text-white font-bungee">
          Initializing..
        </div>
      ) : null}
    </Html>
  );
}
