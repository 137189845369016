import gif from "../assets/page_not_found.gif";

export default function ErrorElement() {
  return (
    <div className="flex flex-col bg-black">
      <div className="flex flex-col px-4 sm:px-16 2xl:px-48 py-8">
        <div className="flex flex-col w-full justify-center items-center text-white font-bungee text-xl md:text-4xl lg:text-3xl">
          Are we lost?
        </div>
      </div>
      <div className="flex flex-col w-full justify-center items-center rounded-xl ">
        <img src={gif} className="object-cover w-128 h-56" alt="my-gif" />
      </div>
      <div className="flex flex-col w-full mt-8 justify-center items-center font-bungee text-xl md:text-4xl lg:text-3xl">
        <a href="/" className="text-blue-500" rel="noreferrer">
          Click here to go home
        </a>
      </div>
    </div>
  );
}
