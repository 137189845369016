import "../App.css";
import Footer from "../components/Footer";
import { Header } from "../components/Header";

export default function Credits() {
  return (
    <div className="container mx-auto h-full text-white p-4">
      <Header />
      <div className="flex flex-col px-4 sm:px-16 2xl:px-48 py-8" id="credits">
        <div className="flex flex-col w-full gap-2 justify-center items-center">
          <div className="font-bungee text-3xl md:text-4xl lg:text-6xl">
            Credits
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="font-bold text-xl">Icons</p>
            <a
              className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
              href="https://www.flaticon.com/free-icons/playstore"
              title="playstore icons"
            >
              Playstore icons created by Freepik - Flaticon
            </a>
            <a
              className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
              href="https://www.flaticon.com/free-icons/twitter-logo"
              title="twitter-logo icons"
            >
              Twitter-logo icons created by Md Tanvirul Haque - Flaticon
            </a>
            <a
              className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
              href="https://www.flaticon.com/free-icons/linkedin"
              title="linkedin icons"
            >
              Linkedin icons created by riajulislam - Flaticon
            </a>
            <a
              className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
              href="https://www.flaticon.com/free-icons/user"
              title="user icons"
            >
              User icons created by Freepik - Flaticon
            </a>
            <a
              className="px-2 rounded-xl cursor-pointer hover:bg-blue-300 hover:text-white"
              href="https://www.flaticon.com/free-icons/web-site"
              title="web site icons"
            >
              Web site icons created by Hilmy Abiyyu A. - Flaticon
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
