import { Helmet } from "react-helmet-async";
interface HelmetInfoProps {
  title: string;
  description: string;
}

export default function HelmetInfo({ title, description }: HelmetInfoProps) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content={"website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:creator" content={"Team Black Box"} />
      <meta name="twitter:card" content={"summary"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}
