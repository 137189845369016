import hashnode from "../assets/hashnode.svg";
import IWontForget from "../assets/i-wont-forget.svg";
import website from "../assets/icons/website.png";
import linkedin from "../assets/linkedin.svg";
import oak from "../assets/oak.svg";
import TakeASip from "../assets/take-a-sip.svg";
import twitter from "../assets/twitter.svg";

export enum SocialHandleType {
  LINKEDIN = "linkedin",
  WEBSITE = "website",
  HASHNODE = "hashnode",
  TWITTER = "twitter",
}

export const SOCIAL_HANDLES_TYPE_TO_IMAGE: Record<SocialHandleType, string> = {
  [SocialHandleType.LINKEDIN]: linkedin,
  [SocialHandleType.WEBSITE]: website,
  [SocialHandleType.HASHNODE]: hashnode,
  [SocialHandleType.TWITTER]: twitter,
};

export enum ProjectsType {
  TAKEASIP = "take-a-sip",
  IWONTFORGET = "i-wont-forget",
  LANDBLOCKS = "land-blocks",
  TICITACATOEY = "oak",
  OAK = "oak",
}

export const PROJECTS_TYPE_TO_IMAGE: Record<string, string> = {
  "take-a-sip": TakeASip,
  "i-wont-forget": IWontForget,
  oak: oak,
};
