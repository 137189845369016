import "../App.css";
import { Header } from "../components/Header";
import HelmetInfo from "../components/HelmetInfo";

export function Internships() {
  return (
    <div className="container mx-auto h-full text-white p-4">
      <HelmetInfo
        title={"Team Black Box Internship"}
        description={"Joining us in Building of the Amaze"}
      />
      <Header />
      <div
        className="flex flex-col px-4 sm:px-16 2xl:px-48 py-8"
        id="internships"
      >
        <div className="flex flex-col w-full gap-2 my-4 justify-center items-center">
          <div className="font-bungee text-3xl md:text-4xl lg:text-6xl">
            Internships
          </div>
          <div className="font-bungee text-xl lg:text-2xl text-blue-500">
            (0 Open Positions | Currently Paused)
          </div>
        </div>
        <div className="flex flex-col gap-4 text-xl font-mono mt-4">
          <p>
            Hello there! Team Black Box was created to build amazing products
            (software and hardware), open source code and content. We call it
            "Building the Amaze".
          </p>
          <p>
            Our internships transformed us and taught us how to build. We spent
            the next many years giving every intern class that experience and
            meeting some of the best people who we call friends.
          </p>
          <p>
            Finally when we started our startup we knew we had to launch our own
            internship program.
          </p>
          <p>So lets get started!</p>
          <span className="font-bungee text-2xl">The TBB Internship Deal</span>
          <p>We have a standard deal for all our interns, here it is</p>
          <p className="text-red-500 font-bold uppercase">0. Deal</p>
          <div className="ml-8 flex flex-col gap-4">
            <p className="text-red-500 font-bold">0.a. Stipend</p>
            <p className="flex flex-col gap-4 font-bold">
              INR. 7,500 per month
            </p>
            <p className="text-red-500 font-bold">0.b. Duration</p>
            <div className="flex flex-col gap-4">
              <p className="font-bold">6 Months</p>
              <p>
                We expect to have 2 batches in a year (starting January & July).
                Applications for each batch usually begin 1 month before.
              </p>
            </div>
            <p className="text-red-500 font-bold">0.c. Certificate</p>
            <div className="flex flex-col gap-4">
              <p>
                You will be provided a certificate of completion at the end of
                your internship.
              </p>
            </div>
            <p className="text-red-500 font-bold">0.d. Who can apply?</p>
            <div className="flex flex-col gap-4">
              <p>We require that:</p>
              <ol className="list-disc ml-10">
                <li>
                  You know how to code
                  <p className="my-2">
                    Simple cli tools or websites in a language of your choice
                    will do
                  </p>
                </li>
                <li>
                  You are not be employed
                  <p className="my-2">
                    Usually employment contracts have intellectual property
                    clauses which would not allow you to be a TBB Intern. Feel
                    free to apply if you think this is not the case (e.g.
                    freelancers/self-employed) and we will evaluate your
                    application and get back to you.
                  </p>
                </li>
                <li>
                  You are authorized to work in India and be paid in INR
                  <p className="my-2">You should have a PAN number.</p>
                </li>
              </ol>
            </div>
            <p className="text-red-500 font-bold">0.e. Location</p>
            <p>Currently TBB internship is fully remote.</p>
            <p>
              We are based out of Bangalore, India - should you wish to drop by
              sometime.
            </p>
            <p>We plan to introduce IRL internships soon!</p>
            <p className="text-red-500 font-bold">0.f. Time Commitment</p>
            <p>
              We expect that you complete your tasks in a reasonable amount of
              time.
            </p>
            <p>
              If you are a student, think of this as a part-time job with a ~35
              hours commitment per week.
            </p>
            <p>
              We do not have fixed working hours, choose the hours that work
              best for you.
            </p>
            <p>
              We expect to have periodic networking events with our advisors
              which you are encouraged to leverage. See 2.d. for more details
            </p>
          </div>
          <p className="text-red-500 font-bold uppercase">1. The Work</p>
          <p className="flex flex-col gap-4">
            You will build products (software and hardware) and open source
            projects of our startups. For example checkout:
            <a
              href="https://teamblackbox.in/apps"
              className="text-blue-500"
              target={"_blank"}
              rel="noreferrer"
            >
              TeamBlackBox Apps!
            </a>
            By the end of your internship, expect to be able to build, test,
            deploy, run, support and scale production applications
          </p>
          <p className="text-red-500 font-bold uppercase">2. Learning</p>
          <div className="flex flex-col gap-4">
            <p>
              You will learn by building, from feedback, by taking ownership and
              leveraging our network.
            </p>
            <div className="ml-8 flex flex-col gap-4">
              <p className="text-red-500 font-bold">2.a. BUILDING</p>
              <div className="flex flex-col gap-4">
                Building includes but is not limited to:
                <ul className="list-disc">
                  <li>Writing version-controlled code</li>
                  <li>Learning and using libraries and frameworks</li>
                  <li>Writing Tests &amp; Debugging</li>
                  <li>
                    Setting up pipelines to test, deploy and run your code
                    automatically
                  </li>
                  <li>Supporting the app</li>
                </ul>
              </div>
              <p className="text-red-500 font-bold">2.b. FEEDBACK</p>
              <div className="flex flex-col gap-4">
                You will receive feedback in various forms (non-exhaustive):
                <ul className="list-disc">
                  <li>Code & Design reviews (by us and our advisors)</li>
                  <li>Bugs, Issues and Incidents</li>
                  <li>Talking to users</li>
                </ul>
              </div>
              <p className="text-red-500 font-bold">2.c. OWNERSHIP</p>
              <div className="flex flex-col gap-4">
                <p>
                  We expect you to take ownership and be accountable for your
                  work (and the products you work on more broadly) during the
                  course of your internship.
                </p>
                <p>
                  Assume that anything you build will have to last for 5 years,
                  so build for stability.
                </p>
                <p>
                  When things go wrong, don't wait for someone else to fix
                  things - be proactive and available to fix the issue and then
                  write tests to ensure it doesn't happen again.
                </p>
              </div>
              <p className="text-red-500 font-bold">2.d. NETWORK</p>
              <div className="flex flex-col gap-4">
                <p>
                  We are fortunate to have an exceptional group of co-founders
                  and technical architects as advisors, who are with us to guide
                  you.
                </p>
                <p>
                  Leverage them for any technical questions your might have or
                  questions on career and entrepreneurship.
                </p>
              </div>
            </div>
          </div>
          <p className="text-red-500 font-bold uppercase">3. The Goal</p>
          <div className="flex flex-col gap-4">
            <p>By the end of this internship we hope that you:</p>
            <ol className="list-decimal pl-16">
              <li>
                have the skills to build and launch any product idea you have
              </li>
              <li>
                build your unique brand so{" "}
                <a
                  href="https://nav.al/money-luck"
                  target={"_blank"}
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  that luck finds you
                </a>
              </li>
            </ol>
            <p>But wait there is more:</p>
            <div className="ml-8 flex flex-col gap-4">
              <p className="text-red-500 font-bold">3.a. Entrepreneurship</p>
              <div className="flex flex-col gap-4">
                <p>
                  We hope that at some point in your career{" "}
                  <a
                    href="https://nav.al/renting-time"
                    className="text-blue-500"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    you start your own company and build something amaze
                  </a>
                </p>
                <p>
                  To that end, if you have an idea for a startup that you wish
                  to pursue at the end of your internship, we might be able to
                  help you build your product, setup your company, and launch.
                  Similar to an angel investment. We plan to formalize and
                  launch a standard deal as part of the Team Black Box incubator
                  sometime next year. Until then feel free to reach out to us
                  directly and we can figure something out.
                </p>
              </div>
              <p className="text-red-500 font-bold">
                3.b. Jobs at Team Black Box
              </p>
              <p className="flex flex-col gap-4">
                We are currently not hiring for any full time roles.
              </p>
              <p className="text-red-500 font-bold">
                3.c. Jobs at other great companies
              </p>
              <p className="flex flex-col gap-4">
                If you are interested, we would be more than happy to put you in
                touch with either:
              </p>
              <ol className="list-decimal pl-16">
                <li>The awesome companies we have previously worked at</li>
                <li>Co-founders of other amaze startups that are hiring</li>
              </ol>
              <p className="text-red-500 font-bold">3.d. Alumni network</p>
              <p className="flex flex-col gap-4">
                At the end of your internship you get to become part of the TBB
                alumni network - where help will always be given to those who
                ask for it. Leverage this community to "Build the Much Amaze".
              </p>
            </div>
          </div>
          <p className="text-red-500 font-bold uppercase">
            4. Application Process
          </p>
          <div className="flex flex-col gap-4">
            <ol className="list-decimal pl-16 flex flex-col gap-4">
              <li>
                <span className="text-red-500">Application</span>
                <p>Fill up the internship application here</p>
                <button className="p-4 m-2 bg-blue-700 rounded">
                  Currently Paused
                </button>
              </li>
              <li>
                <span className="text-red-500">Video Interview 1</span>
                <p>
                  We then select a few candidates based on the internship
                  application for a chat
                </p>
              </li>
              <li>
                <span className="text-red-500">Build a Simple App</span>
                <p>
                  If you qualify through the first video interview we will ask
                  you to build a simple app (with one or more components like:
                  website, native app, and server) and publish its code on
                  Github (public or private repo (with us as collaborators))
                </p>
                <p>The app should take 1-2 weeks or less to build</p>
                <p>
                  We will periodically review your code to provide feedback and
                  request changes during this build
                </p>
              </li>
              <li>
                <span className="text-red-500">Video Interview 2</span>
                <p>
                  We will review your application and code and if everything
                  looks good we will invite you for a second round of Video
                  Interview focused primarily on the app
                </p>
              </li>
              <li>
                <span className="text-red-500">Welcome to the team!</span>
                <p>
                  If you qualify from your second video interview, you will
                  officially be invited to join the TBB Internship class! We
                  will send you the internship agreement and walk you through
                  it, once you sign you are in the program!
                </p>
              </li>
            </ol>
          </div>
          <p>Important Notes:</p>
          <div className="flex flex-col gap-4">
            <ol className="list-decimal pl-16 flex flex-col gap-4">
              <li>
                <span className="text-red-500">Timeline</span>
                <p>
                  The application process starts 1 month before the next batch
                  and lasts for a month
                </p>
              </li>
              <li>
                <span className="text-red-500">Rejections</span>
                <p>
                  If you are rejected at any stage of the application process,
                  you will be notified by email. We do not provide feedback on
                  why your application was rejected. To understand why we don't,
                  read this post -{" "}
                  <a
                    href="https://www.ycombinator.com/whynot"
                    target={"_blank"}
                    className="text-blue-500"
                    rel="noreferrer"
                  >
                    YCombinator: Why Not
                  </a>
                </p>
              </li>
              <li>
                <span className="text-red-500">Re-applying</span>
                <p>
                  If you are rejected, you may reapply for the next batch of TBB
                  internship
                </p>
              </li>
              <li>
                <span className="text-red-500">Application Copyright</span>
                <p>
                  The app that you build as part of the interview belongs to
                  you. TBB will not use your code without your written
                  permission. If you get accepted as a TBB Intern, the code you
                  write for us after that will be govered by your Internship
                  Agreement.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
