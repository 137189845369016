import { Float, OrbitControls, RoundedBox, Stars } from '@react-three/drei';
import { Canvas, ThreeElements, useFrame } from '@react-three/fiber';
import { Bloom, EffectComposer } from '@react-three/postprocessing';
import { Suspense, useRef, useState } from 'react';
import { Color } from 'three';
import { isMacDevice } from '../utils/util';
import { Loader } from './Loader';

const BLUE_COLOR: Color = new Color(1, 2, 3.2);

export default function AmazeAnimation() {
  const [linear, setLinear] = useState(true);
  const bloomRadius = isMacDevice() ? 0.2 : 0.7;

  return (
    <Canvas
      onPointerDown={() => {
        setLinear(false);
      }}
      onPointerUp={() => {
        setLinear(true);
      }}
      linear={linear}
      style={{height: '30vh'}}>
      <Suspense fallback={<Loader />}>
        <pointLight
          position={[10, 10, -10]}
          color={BLUE_COLOR}
          intensity={0.5}
        />
        <pointLight position={[10, -10, -10]} color={BLUE_COLOR} />
        <pointLight
          position={[0, 10, -10]}
          color={BLUE_COLOR}
          intensity={0.2}
        />
        <pointLight
          position={[10, 10, 10]}
          color={BLUE_COLOR}
          intensity={0.1}
        />
        {/* <Sphere
        args={[0.3, 20, 20]}
        position={[6, 7, -12]}
        onClick={() => {
          setLinear(!linear);
        }}>
        <meshBasicMaterial color={[1, 2, 3]} toneMapped={false} />
      </Sphere> */}
        <Float speed={1.2} rotationIntensity={1} floatIntensity={2}>
          <Box position={[0, 0, 0]} />
        </Float>
        <Stars saturation={4} count={500} fade={true} speed={1} />
        <EffectComposer>
          <Bloom mipmapBlur luminanceThreshold={1} radius={bloomRadius} />
        </EffectComposer>
        <OrbitControls
          enableDamping={true}
          autoRotate={true}
          autoRotateSpeed={0.1}
          enableZoom={false}
        />
      </Suspense>
    </Canvas>
  );
}

function Box(props: ThreeElements['mesh']) {
  const mesh = useRef<THREE.Mesh>(null!);
  const [active, setActive] = useState(false);
  useFrame((state, delta) => {
    mesh.current.rotation.x += delta + Math.random() * 0.001;
    mesh.current.rotation.y += delta + Math.random() * 0.0005;
  });
  return (
    <mesh
      {...props}
      ref={mesh}
      // onPointerEnter={() => setShiny(true)}
      // onPointerLeave={() => setShiny(false)}
      castShadow={true}
      onClick={_event => setActive(!active)}>
      <RoundedBox args={[1, 1, 1]} radius={0.1}>
        <meshPhysicalMaterial
          clearcoat={0.1}
          clearcoatRoughness={0.2}
          transmission={1}
          thickness={1.2}
          roughness={0.2}
          toneMapped={false}
        />
      </RoundedBox>
    </mesh>
  );
}
