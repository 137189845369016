export default function Footer() {
  return (
    <div>
      <div className="text-center">© 2023 TeamBlackBox Private Limited</div>
      <div className="text-center">
        TeamBlackBox Private Limited, 91 Springboard Business Hub Private Limited, 512/10 Service Lane Outer Ring Road, Mahadevapura, Bangalore, Karnataka, India, 560048
      </div>
      <div className="text-center pb-8">support @ teambackbox.in</div>
    </div>
  );
}
