
import { AppDetails } from "../model/types";

interface AppCardProps {
  app: AppDetails;
}

export function AppCard(props: AppCardProps) {
  const app = props.app;
  const wipGrayscale = app.wip ? "grayscale" : "grayscale-0";
  return (
    <div className="flex flex-col p-4 cursor-pointer hover:bg-slate-700 rounded-xl justify-center items-center gap-2 w-[250px]" onClick={() => window.open(app.url, "_blank")}>
        <img
          src={app.iconUrl}
          alt={app.name}
          className={`bg-cover h-auto w-[150px] rounded-xl ${wipGrayscale}`}
        />
        <h4 className="text-lg font-bold text-white font-mono">
          {app.title}
        </h4>
        <p className="font-light text-base text-center text-gray-600 font-mono">
          {app.description}
        </p>
        {/* <p className={`flex flex-row gap-2 mb-6 max-h-20 ${wipGrayscale}`}> */}
          {/* {app.links.playStore && (
            <img
              src={PlayStoreIcon}
              onClick={(e) => {
                window.open(app.links.playStore, "_blank");
                e.stopPropagation();
              }}
              alt={"Play store icon"}
              className="mb-3 h-8 w-8 rounded-xl hover:border-2 cursor-pointer"
            />
          )}
          {app.links.appleStore && (
            <img
              src={AppStoreIcon}
              onClick={(e) => {
                window.open(app.links.appleStore, "_blank");
                e.stopPropagation();
              }}
              alt={"App store icon"}
              className="mb-3 h-8 w-8 rounded-xl hover:border-2 cursor-pointer"
            />
          )} */}
          {/* {app.url && (
            <img
              src={WebsiteIcon}
              onClick={(e) => {
                window.open(app.url, "_blank");
                e.stopPropagation();
              }}
              alt={"Webiste icon"}
              className="mb-3 h-8 w-8 rounded-xl hover:border-2 cursor-pointer"
            />
          )} */}
          {/* {app.links.github && (
            <img
              src={GithubIcon}
              onClick={(e) => {
                window.open(app.links.github, "_blank");
                e.stopPropagation();
              }}
              alt={"Github icon"}
              className="mb-3 h-8 w-8 rounded-xl hover:border-2 cursor-pointer"
            />
          )} */}
        {/* </p> */}
        {/* <div
          className={`text-center text-white font-mono rounded-2xl border-slate-400 mx-6 ${
            app.isHackathonApp ? "opacity-100" : "opacity-0"
          }`}
        >
          Hackathon App
        </div> */}
        {/* {app.wip ? (
          <div className="bg-darkblue text-center text-red-600 font-bungee rounded-3xl p-4">
            Work in Progress
          </div>
        ) : (
          <div className="bg-darkblue text-center text-blue-700 font-mono rounded-3xl p-4">
            Dates: {app.dates}
          </div>
        )} */}
    </div>
  );
}
