import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Amazer from "./routes/Amazer";
import Credits from "./routes/Credits";
import ErrorElement from "./routes/ErrorElement";
import Home from "./routes/Home";
import { Internships } from "./routes/Internships";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMsdsBk68tGHFmCk96XTRbzg6VFBb8q2U",
  authDomain: "team-black-box.firebaseapp.com",
  projectId: "team-black-box",
  storageBucket: "team-black-box.appspot.com",
  messagingSenderId: "1046845777257",
  appId: "1:1046845777257:web:3f07e26e0c68b6140909f8",
  measurementId: "G-TSJYBBMGCB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorElement />}>
      <Route
        path="/"
        element={<Home />}
        errorElement={<ErrorElement />}
      ></Route>
      <Route path="/internships" element={<Internships />} />
      {/* <Route path="/apps" element={<Apps />} /> */}
      {/* <Route path="/blog" element={<BlogHome />} /> */}
      {/* <Route path="/apps/:name" element={<App />} /> */}
      <Route path="/credits" element={<Credits />} />
      <Route path="/i/:id" element={<Amazer />} />
      {/* <Route path="/about-us" element={<About />} /> */}
      {/* <Route path="/contact" element={<Contact />} /> */}
    </Route>
  )
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
