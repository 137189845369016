import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import "../App.css";
import MapMarker from "../assets/map-marker.svg";
import programmer_M from "../assets/programmer_M.png";
import ProjectIcon from "../assets/projectIcon.svg";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import HelmetInfo from "../components/HelmetInfo";
import {
  PROJECTS_TYPE_TO_IMAGE,
  ProjectsType,
  SOCIAL_HANDLES_TYPE_TO_IMAGE,
  SocialHandleType,
} from "../constants/constants";
import TEAM_MEMBERS from "../data/team";
import { SocialHandles, TeamMember } from "../model/types";
import ErrorElement from "./ErrorElement";

const SocialHandle: React.FC<{ teamMember: TeamMember }> = ({ teamMember }) => {
  return (
    <>
      {Object.keys(teamMember.socialHandles).map((key: string) => (
        <a
          key={key}
          href={teamMember.socialHandles[key as keyof SocialHandles]}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={SOCIAL_HANDLES_TYPE_TO_IMAGE[key as SocialHandleType]}
            width={40}
            height={40}
            className="mx-1"
            alt={key}
          />
        </a>
      ))}
    </>
  );
};

const ProjectsWorkedOn: React.FC<{ teamMember: TeamMember }> = ({
  teamMember,
}) => {
  return (
    <>
      {teamMember.projectsWorkedOn.map((key: string) => (
        <a key={key} href={`/apps/${key}`} target="_blank" rel="noreferrer">
          <img
            src={PROJECTS_TYPE_TO_IMAGE[key as ProjectsType] ?? ProjectIcon}
            width={40}
            height={40}
            className="mx-1"
            alt={key}
          />
        </a>
      ))}
    </>
  );
};

export default function Amazer() {
  let { id } = useParams();
  const [markDownBody, setMarkDownBody] = useState<string>("");

  const amazer = id ? TEAM_MEMBERS[id] : undefined;

  useEffect(() => {
    const getReadMe = async (batchNo: string, internId: number) => {
      try {
        const res = await fetch(
          `https://raw.githubusercontent.com/team-black-box/tbb-interns/test/${batchNo}/${internId}/README.md`
        );

        if (res.status === 200) {
          const data = await res.text();
          setMarkDownBody(data);
        } else {
          setMarkDownBody("");
        }
      } catch (error) {
        setMarkDownBody("");
      }
    };

    if (amazer) {
      getReadMe(amazer.batch, amazer.internId);
    }
  }, [amazer]);

  return (
    <>
      {amazer !== undefined ? (
        <div className="container mx-auto h-full text-white p-4">
          <HelmetInfo
            title={"TBB - " + amazer.firstName + " " + amazer.lastName}
            description={"TBB Amaze Builder"}
          />
          <Header />
          <div
            className="flex flex-col px-4 sm:px-16 2xl:px-48 py-8"
            id="amazer"
          >
            <div className="flex flex-row justify-center self-center pt-10 pb-10">
              <img
                src={amazer.imagePath ?? programmer_M}
                className="h-48 w-48 rounded-full"
                alt={"Programmer Logo"}
              />
            </div>
            <div className="font-bungee pb-5 text-center text-2xl md:text-2xl lg:text-4xl">
              {`${amazer.firstName} ${amazer.lastName}`}
            </div>
            <div className="flex pb-3 flex-col md:flex-row lg:flex-row w-full gap-2 justify-center items-center">
              <div className="font-mono text-xl md:text-xl lg:text-2xl">
                Intern: #{amazer.internId} {amazer.batch}
              </div>
              {" | "}
              <div className="flex items-center font-monotext-xl md:text-xl lg:text-2xl">
                <img src={MapMarker} className="mr-2" alt="MapMarker" />
                {amazer.location}
              </div>
            </div>
            <div className="flex flex-col md:flex-row lg:flex-row w-full gap-2 justify-center items-center">
              <div className="flex font-mono text-xl md:text-xl lg:text-2xl">
                Social Handles:
                <SocialHandle teamMember={amazer} />
              </div>
              {" | "}
              <div className="flex font-mono text-xl md:text-xl lg:text-2xl">
                Worked On:
                <ProjectsWorkedOn teamMember={amazer} />
              </div>
            </div>
          </div>
          <div className="markdown-body font-mono pb-40 px-4 sm:px-16 2xl:px-48 py-8">
            {markDownBody.length > 0 ? (
              <ReactMarkdown
                children={markDownBody}
                remarkPlugins={[remarkGfm]}
              />
            ) : (
              " "
            )}
          </div>
          <Footer />
        </div>
      ) : (
        <ErrorElement />
      )}
    </>
  );
}
