import { Team } from "../model/types";
const TEAM_MEMBERS: Team = {
  ymb: {
    id: "ymb",
    batch: "2023.1",
    internId: 5,
    firstName: "Yahaya",
    lastName: "Muhammad Bello",
    imagePath:
      "https://raw.githubusercontent.com/team-black-box/tbb-interns/test/2023.1/5/assets/yahaya-muhammad-bello.svg",
    location: "Maiduguri, Nigeria",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/yahaya-muhammad-bello-488159188/",
      twitter: "https://twitter.com/mbyahya_",
      hashnode: "https://mbyahya.hashnode.dev/",
      website: "https://abiey2579.github.io/",
    },
    projectsWorkedOn: ["take-a-sip", "i-wont-forget"],
  },
  kalp: {
    id: "kalp",
    batch: "2023.1",
    internId: 6,
    firstName: "Kalp",
    lastName: "Adhwaryu",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/6.jpg",
    location: "Vadodara, Gujarat",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/kalpadhwaryu/",
      twitter: "https://twitter.com/kalp_adhwaryu",
    },
    projectsWorkedOn: ["take-a-sip", "i-wont-forget", "oak"],
  },
  suparv: {
    id: "suparv",
    batch: "2023.1",
    internId: 3,
    firstName: "Suparv",
    lastName: "Lakhanpal",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/3.jpg",
    location: "Chandigarh, India",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/suparv-lakhanpal-297731193/",
    },
    projectsWorkedOn: ["take-a-sip", "i-wont-forget", "oak"],
  },
  adtyavrdhn: {
    id: "adtyavrdhn",
    batch: "2023.1",
    internId: 1,
    firstName: "Aditya",
    lastName: "Vardhan",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/default_male.png",
    location: "Mysuru, India",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/aditya-vardhan-03/",
    },
    projectsWorkedOn: [
      "take-a-sip",
      "tici-taca-toey",
      "land-blocks",
      "i-wont-forget",
    ],
  },
  shruthi: {
    id: "shruthi",
    batch: "2022.2",
    internId: 0,
    firstName: "Shruthi",
    lastName: "Abirami P",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/default_female.png",
    location: "Bengaluru, India",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/shruthi-abirami-76a01217b/",
    },
    projectsWorkedOn: [
      "oak",
      "take-a-sip",
      "tici-taca-toey",
      "land-blocks",
      "i-wont-forget",
    ],
  },
  siftee: {
    id: "siftee",
    batch: "2023.1",
    internId: 4,
    firstName: "Siftee",
    lastName: "Ratra",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/4.jpg",
    location: "Delhi, India",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/siftee-ratra-2245a7251/",
    },
    projectsWorkedOn: ["take-a-sip", "i-wont-forget", "oak"],
  },
  ekaksh: {
    id: "ekaksh",
    batch: "2023.1",
    internId: 2,
    firstName: "Ekaksh",
    lastName: "Janweja",
    imagePath:
      "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/2.jpg",
    location: "Delhi, India",
    socialHandles: {
      linkedin: "https://www.linkedin.com/in/ekakshjanweja/",
      twitter: "https://twitter.com/ekaksh_janweja",
      hashnode: "https://stormej.hashnode.dev/",
      website: "https://stormej.me/#/",
    },
    projectsWorkedOn: ["take-a-sip", "i-wont-forget", "land-blocks"],
  },
};

export default TEAM_MEMBERS;
