import Logo from '../logo.png';

interface HeaderLink {
  id: string;
  displayText: string;
  hrefLink: string;
}

export const LINKS: Record<string, HeaderLink> = {
  internships: {
    id: 'internships',
    displayText: 'Internships',
    hrefLink: '/internships',
  },
  apps: {
    id: 'apps',
    displayText: 'Apps',
    hrefLink: '/apps',
  },
  blog: {
    id: 'blog',
    displayText: 'Blog',
    hrefLink: '/blog',
  },
  aboutUs: {
    id: 'about-us',
    displayText: 'About Us',
    hrefLink: '/about-us',
  },
  contact: {
    id: 'contact',
    displayText: 'Contact',
    hrefLink: '/contact',
  },
};

const HeaderLinkComponent = ({id, hrefLink, displayText}: HeaderLink) => {
  const isSelected = window.location.href.endsWith(hrefLink);
  return (
    <a
      href={hrefLink}
      className={`xl:text-lg md:text-xs font-mono p-2 rounded select-none cursor-pointer ${
        isSelected
          ? 'border-blue-700 border-b pb-1 pointer-events-none'
          : 'hover:bg-blue-700'
      }`}>
      {displayText}
    </a>
  );
};

export const Header = () => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center justify-center gap-4 px-8 pt-12">
        <img
          src={Logo}
          className="h-10 w-10 border border-white rounded cursor-pointer select-none"
          alt={'Team Black Box Logo'}
          onClick={() => window.open('/', '_self')}
        />
        <div className="text-xl md:text-4xl font-bungee text-center select-none">
          Team Black Box
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-2 py-3 select-none">
        {Object.keys(LINKS).map((key: string) => {
          const link = LINKS[key];
          return (
            <HeaderLinkComponent
              key={link.id}
              id={link.id}
              displayText={link.displayText}
              hrefLink={link.hrefLink}
            />
          );
        })}
      </div>
    </div>
  );
};
