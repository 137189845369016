import { AppsRecord } from "../model/types";
const APPS: AppsRecord = {
  oak: {
    id: "oak",
    name: "Oak",
    iconUrl: "https://oakapp.in/logo512.png",
    url: "https://oakapp.in",
    title: "Oak",
    description:
      "Estate Management Simplified. Save Time. Reduce Costs. Grow Your Estate.",
    contributerIds: ["shruthi", "suparv", "siftee", "kalp"],
    dates: "Work in progress",
    isHackathonApp: false,
    wip: true,
    links: {
      github: "https://github.com/team-black-box/oak-app/",
    },
  },
  
  "i-wont-forget": {
    id: "i-wont-forget",
    name: "I Won't Forget",
    iconUrl: "https://iwontforget.app/logo512.png",
    url: "https://iwontforget.app",
    title: "I Won't Forget",
    description:
      "I Won't Forget helps you create simple or recurring reminder schedules and reminds you on your favorite apps.",
    contributerIds: [
      "shruthi",
      "adtyavrdhn",
      "ekaksh",
      "suparv",
      "siftee",
      "kalp",
      "ymb",
    ],
    dates: "22nd - 23rd Jan '23",
    links: {
      playStore:
        "https://play.google.com/store/apps/details?id=app.iwontforget",
      appleStore: "https://apps.apple.com/app/id1667093471",
      github: "https://github.com/team-black-box/i-wont-forget/",
    },
    isHackathonApp: true,
    wip: false,
  },
  "take-a-sip": {
    id: "take-a-sip",
    name: "Take a Sip",
    iconUrl: "https://takeasip.app/logo512.png",
    url: "https://takeasip.app",
    title: "Take a Sip",
    description:
      "Take a Sip is an easy to use, and beautiful app to help you keep yourself hydrated.",
    contributerIds: ["shruthi", "adtyavrdhn"],
    dates: "27th - 28th Dec '22",
    links: {
      playStore: "https://play.google.com/store/apps/details?id=app.takeasip",
      appleStore: "https://apps.apple.com/app/id6444727522",
      github: "https://github.com/team-black-box/take-a-sip/",
    },
    isHackathonApp: true,
    wip: false,
  },

  // "land-blocks": {
  //   id: "land-blocks",
  //   name: "Land Blocks",
  //   iconUrl:
  //     "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/coming-soon.png",
  //   title: "Land Blocks",
  //   description: "-",
  //   contributerIds: ["adtyavrdhn", "ekaksh"],
  //   isHackathonApp: false,
  //   wip: true,
  //   dates: "Work in progress",
  //   links: {
  //     github: "https://github.com/team-black-box/land-blocks/",
  //   },
  // },
  // "tici-taca-toey": {
  //   id: "tici-taca-toey",
  //   name: "Tici Taca Toey",
  //   iconUrl:
  //     "https://storage.googleapis.com/team-black-box.appspot.com/assets/intern-image/coming-soon.png",
  //   title: "Tici Taca Toey",
  //   description: "Tic Tac Toe re-imagined",
  //   contributerIds: [
  //     "shruthi",
  //     "adtyavrdhn",
  //     "ekaksh",
  //     "suparv",
  //     "siftee",
  //     "kalp",
  //     "ymb",
  //   ],
  //   dates: "Work in progress",
  //   links: {
  //     github: "https://github.com/team-black-box/i-wont-forget/",
  //   },
  //   isHackathonApp: false,
  //   wip: true,
  // },
};

export default APPS;
